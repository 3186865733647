import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactPaginate from 'react-paginate';
import useSWR from 'swr';
import { fetcher, tmdbAPI } from '../../apiConfig/config';
import Dishes, { DishSkeleton } from '../../components/product/Dishes';
import content from '../../data/lgMenu';
import useDebounce from '../../hooks/useDebounce';
import Banner from '../../layouts/banner/Banner';
import checkLanguage, { handleCheckLgAll } from '../../utils/checkLg';
import { animateScroll as scroll } from 'react-scroll';

const Menu = ({ language }) => {
	const { v4: uuidv4 } = require('uuid');
	const contentPage = checkLanguage(language, content);
	const { banner, search, empty } = contentPage;
	const [categoryState, setCategoryState] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);

	const [nextPage, setNextPage] = useState(1);
	const [filter, setFilter] = useState('');
	const [url, setUrl] = useState(tmdbAPI.getDishList(categoryState, nextPage));
	const filterDebounce = useDebounce(filter, 500);

	// Resize Responsive
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});
	const [isMobile, setIsMobile] = useState(false);

	const { data, error } = useSWR(url, fetcher);
	const loading = !data && !error;

	const handlerOnChange = (e) => {
		setFilter(e.target.value);
	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * data.per_page) % data.total;
		setItemOffset(newOffset);
		setNextPage(event.selected + 1);

		scroll.scrollToTop();

		// setNextPage(event.selected + 1);
		// setUrl(
		// 	tmdbAPI.getDishSearch(filterDebounce, event.selected + 1, categoryState)
		// );
	};

	const dishes = data?.data || [];

	useEffect(() => {
		if (!data || !data.total) return;
		setPageCount(Math.ceil(data.total / data.per_page));
	}, [data, itemOffset]);

	// useEffect(() => {
	// 	if (!data || !data.total) return;
	// 	setPageCount(Math.ceil(data.total / data.per_page));
	//   }, [data, filterDebounce, categoryState]);

	useEffect(() => {
		if (filterDebounce && filterDebounce !== '') {
			setUrl(tmdbAPI.getDishSearch(filterDebounce, 1, categoryState));
		} else if (filterDebounce && filterDebounce === '') {
			setUrl(tmdbAPI.getDishSearch(filterDebounce, 1, categoryState));
		} else {
			setUrl(tmdbAPI.getDishList(categoryState, nextPage));
		}
	}, [filterDebounce, nextPage, categoryState]);

	// Resize Responsive
	useEffect(() => {
		const handleSize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		handleSize();
		window.addEventListener('resize', handleSize);
		return () => window.addEventListener('resize', handleSize);
	}, []);

	useEffect(() => {
		if (windowSize.width <= 979) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [windowSize]);

	return (
		<Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Alo Nhậu - Menu</title>
				<link rel="canonical" href="/menu" />
				<meta name="description" content="Simple React SEO Menu Application" />
			</Helmet>

			<section className="banner relative min-h-[450px] fl:min-h-[250px] mb:min-h-[200px] mb:mb-14">
				<Banner title={banner.nav} language={language}>
					{banner.title}
				</Banner>
			</section>

			<section className="page-container mb-[80px] mb:mb-20 about-reserve after:top-[70%]">
				<div className="flex mb-8 -mt-10 overflow-hidden rounded-lg shadow-item max-w-[90%] mx-auto mb:mt-0 mb:max-w-full">
					<div className="flex-1">
						<input
							type="text"
							className="w-full py-5 px-6 text-white outline-none text-lg bg-[#29292c] mb:py-4 mb:px-4 mb:pr-2"
							placeholder={search}
							onChange={handlerOnChange}
						/>
					</div>

					<button className="p-4 pr-6 text-white bg-[#29292c] mb:py-4">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
							/>
						</svg>
					</button>
				</div>

				<div
					className={`flex  mb:grid-cols-1 mb:mt-10 ${
						isMobile ? 'flex-col mt-8' : 'mt-14'
					}`}
				>
					<MenuCheck
						categoryArr={setCategoryState}
						language={language}
						checkMb={isMobile}
					></MenuCheck>

					<div className="w-full">
						{loading && (
							<div className="grid grid-cols-2 mb:grid-cols-1">
								<DishSkeleton></DishSkeleton>
								{new Array(8).fill(0).map(() => (
									<DishSkeleton key={uuidv4()}></DishSkeleton>
								))}
							</div>
						)}

						<div className="grid grid-cols-2 gap-y-9 mb:gap-y-7 mb:grid-cols-1">
							{!loading &&
								(dishes.length > 0 ? (
									dishes.map((item, index) => (
										<Dishes
											key={item.id}
											data={item}
											language={language}
										></Dishes>
									))
								) : (
									<div className="col-span-2 text-4xl font-normal text-center mat:text-[28px] font-lora">
										{empty}
									</div>
								))}
						</div>
					</div>
				</div>

				<div className="mt-28 mat:mt-12">
					<ReactPaginate
						breakLabel="..."
						nextLabel="Next"
						onPageChange={handlePageClick}
						pageRangeDisplayed={5}
						pageCount={pageCount}
						previousLabel="Prev"
						renderOnZeroPageCount={null}
						className="pagination"
					/>
				</div>
			</section>
		</Fragment>
	);
};

const MenuCheck = ({ categoryArr: setCategoryState, language, checkMb }) => {
	const [valueCheck, setValueCheck] = useState([]);
	const [isSticky, setIsSticky] = useState(false);
	const { v4: uuidv4 } = require('uuid');
	const { data } = useSWR(tmdbAPI.getCategory('category'), fetcher);
	const listCategory = data?.data || [];

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const handleScroll = () => {
		const menuMain = document.querySelector('.menu-nav');
		const menuOffsetTop = menuMain.getBoundingClientRect().top + 373;

		if (window.pageYOffset > menuOffsetTop) {
			setIsSticky(true);
		} else {
			setIsSticky(false);
		}
	};

	const onHandleCheck = (e) => {
		let updatedList = [];
		if (e.target.checked) {
			updatedList = [Number.parseInt(e.target.value)];
		} else {
			updatedList = [];
		}

		setValueCheck(updatedList);
		setCategoryState(
			JSON.stringify(updatedList.includes(0) ? [] : updatedList) || []
		);
	};

	return (
		<div
			className={`${
				checkMb
					? 'overflow-x-auto pb-6 mb-4 min-h-[40px]'
					: 'min-w-[178px] w-[178px] mr-8'
			}`}
		>
			<div
				className={`flex menu-nav transition-all ${
					isSticky
						? ' fixed top-[95px] mt-8 mat:mt-2 w-[178px] z-[99]  mat:relative mat:top-0'
						: ''
				} ${
					checkMb
						? 'items-center space-x-6 font-lora max-w-full mx-auto'
						: ' flex-col text-center'
				} mb:max-w-full mat:space-x-3 mb:justify-between`}
			>
				<label
					key={uuidv4()}
					htmlFor="allMenu"
					className={`capitalize ${
						checkMb ? ' flex-shrink-0' : 'mb-5 shadow-intro'
					} py-2 px-7 rounded-3xl select-none cursor-pointer ${
						valueCheck.includes(0)
							? 'bg-primary text-black'
							: 'bg-[#414141] text-white'
					}`}
				>
					<input
						type="radio"
						id="allMenu"
						name="menu-list"
						value="0"
						className="hidden"
						onClick={onHandleCheck}
					/>
					{handleCheckLgAll(language)}
				</label>

				{listCategory.length > 0 &&
					listCategory.map((item) => (
						<label
							key={item.id}
							htmlFor={item.id}
							className={`capitalize ${
								checkMb ? ' flex-shrink-0' : 'mb-5 shadow-intro'
							} py-2 px-7 rounded-3xl select-none cursor-pointer ${
								valueCheck.includes(item.id)
									? 'bg-primary text-black'
									: 'bg-[#414141] text-white'
							}`}
						>
							<input
								type="radio"
								id={item.id}
								name="menu-list"
								value={item.id}
								className="hidden"
								onClick={onHandleCheck}
							/>
							{language === 'cn'
								? item.name_cn
								: language === 'vn'
								? item.name_vi
								: item.name}
						</label>
					))}
			</div>
		</div>
	);
};

export default Menu;
