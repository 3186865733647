import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './footer/Footer';
import Header from './header/Header';

const Main = ({ language, handleSetLanguage }) => {
	return (
		<Fragment>
			<Header
				language={language}
				handleSetLanguage={handleSetLanguage}
			></Header>
			<Outlet></Outlet>
			<Footer language={language}></Footer>
		</Fragment>
	);
};

export default Main;
