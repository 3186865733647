import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
// import store from "./store";

import { BrowserRouter } from 'react-router-dom';
import store from './redux';
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement || null);

if (rootElement?.hasChildNodes()) {
	hydrateRoot(
		rootElement,
		// <React.StrictMode>
		<Provider store={store}>
			<HelmetProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</HelmetProvider>
		</Provider>
		// </React.StrictMode>
	);
} else {
	root.render(
		// <React.StrictMode>
		<Provider store={store}>
			<HelmetProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</HelmetProvider>
		</Provider>
		// </React.StrictMode>
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
