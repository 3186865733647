import detectBrowserLanguage from 'detect-browser-language';
import { Fragment, lazy, Suspense } from 'react';

import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './layouts/Main';
import NotFound from './layouts/notFound/NotFound';
import Blog from './pages/blog/Blog';
import BlogDetail from './pages/blog/BlogDetail';
import Menu from './pages/menu/Menu';
// import HomePage from './pages/home/HomePage';
// import AboutPage from './pages/about/AboutPage';
// import Contact from './pages/contact/Contact';
// import DetailFood from './pages/details/DetailFood';

const HomePage = lazy(() => import('./pages/home/HomePage'));
const AboutPage = lazy(() => import('./pages/about/AboutPage'));
const Contact = lazy(() => import('./pages/contact/Contact'));
const DetailFood = lazy(() => import('./pages/details/DetailFood'));

function storeLanguageInLocalStorage(language) {
	localStorage.setItem('language', language);
}

function App() {
	const languageDefault = detectBrowserLanguage();
	const listLanguage = ['zh-TW', 'zh-CN'];
	const listDeaf = ['cn', 'vn', 'en'];
	const isLanguageCN = listLanguage.includes(languageDefault);

	let languageStoredInLocalStorage = localStorage.getItem('language');
	let [language, setLanguage] = useState(
		listDeaf.includes(languageStoredInLocalStorage?.toLowerCase())
			? languageStoredInLocalStorage?.toLowerCase()
			: isLanguageCN
			? 'cn'
			: languageDefault === 'vi'
			? 'vn'
			: 'en'
	);

	return (
		<Fragment>
			<Suspense fallback={<></>}>
				<Routes>
					<Route
						element={
							<Main
								language={language}
								handleSetLanguage={(language) => {
									setLanguage(language);
									storeLanguageInLocalStorage(language);
								}}
							></Main>
						}
					>
						<Route
							exact
							path="/"
							element={
								<>
									<HomePage language={language} />
								</>
							}
						></Route>
						<Route
							path="/menu/:slug"
							element={<DetailFood language={language}></DetailFood>}
						>
							{/* <Route index element={<DetailDesc />} /> */}
							{/* <Route path="gallery" element={<DetailGallery />} /> */}
						</Route>
						<Route
							exact
							path="/about"
							element={<AboutPage language={language}></AboutPage>}
						></Route>
						<Route
							exact
							path="/contact"
							element={<Contact language={language}></Contact>}
						></Route>
						<Route
							exact
							path="/blog"
							element={<Blog language={language}></Blog>}
						></Route>
						<Route
							path="/menu"
							element={<Menu language={language}></Menu>}
						></Route>
						<Route
							path="/blog/:blogId"
							element={<BlogDetail language={language}></BlogDetail>}
						></Route>
						<Route path="*" element={<NotFound></NotFound>}></Route>
					</Route>
				</Routes>
			</Suspense>
		</Fragment>
	);
}

export default App;
